import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import {
  // axiosDeleteData,
  axiosGetData,
  axiosGetParallelData,
  axiosPostData,
  axiosPutData,
  buildQueryStringFromParams,
} from '../../helpers/axios';

export const addAllItemsToOrderCreate = createAsyncThunk(
  'addAllItemsToOrderCreate',
  async ({ vendorId, orderId, data, onSuccess }, { rejectWithValue }) =>
    Promise.all(
      data.variant_ids.map((variant) =>
        axiosPostData({
          rejectWithValue,
          url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/stage-items/`,
          data: {
            variant_ids: [variant],
          },
          onSuccess,
        })
      )
    )
);

export const addItemToOrderCreate = createAsyncThunk(
  'addItemToOrderCreate',
  async ({ vendorId, orderId, data, onSuccess }, { rejectWithValue }) =>
    axiosPostData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/stage-items/`,
      data,
      onSuccess,
    })
);
export const deleteAllItemsFromOrderCreate = createAsyncThunk(
  'deleteAllItemsFromOrderCreate',
  async (
    { vendorId, orderId, orderedItemIds, onSuccess },
    { rejectWithValue }
  ) =>
    Promise.all(
      orderedItemIds.map((orderedItemId) =>
        axiosPostData({
          rejectWithValue,
          url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/item/${orderedItemId}/delete/`,
          onSuccess,
        })
      )
    )
);

export const deleteItemFromOrderCreate = createAsyncThunk(
  'deleteItemFromOrderCreate',
  async (
    { vendorId, orderId, orderedItemId, onSuccess },
    { rejectWithValue }
  ) =>
    axiosPostData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/item/${orderedItemId}/delete/`,
      onSuccess,
    })
);

export const getOrderCreateCustomers = createAsyncThunk(
  'getOrderCreateCustomers',
  async ({ vendorId }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/customers/`,
    })
);

export const getOrderCreateLocations = createAsyncThunk(
  'getOrderCreateLocations',
  async ({ dispensaryId, vendorId }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/customers/${dispensaryId}/locations`,
    })
);

export const getOrderCreateProductVariants = createAsyncThunk(
  'getOrderCreateProductVariants',
  async (
    { orderId, vendorId, requestParams, onSuccess },
    { rejectWithValue }
  ) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/variants/${
        requestParams ? `?${buildQueryStringFromParams(requestParams)}` : ''
      }`,
      onSuccess,
    })
);

export const getOrderCreateStockLocations = createAsyncThunk(
  'getOrderCreateStockLocations',
  async ({ vendorId }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: ` /dashboard/vendor/${vendorId}/stock-locations/`,
    })
);

export const getOrderGridInfo = createAsyncThunk(
  'getOrderGridInfo',
  async ({ onSuccess, orderId, vendorId }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/grid/`,
      onSuccess,
    })
);

export const getOrderGridItems = createAsyncThunk(
  'getOrderGridItems',
  async (
    { onSuccess, orderId, vendorId, requestParams },
    { rejectWithValue }
  ) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/grid-items/${
        requestParams ? `?${buildQueryStringFromParams(requestParams)}` : ''
      }`,
    })
);

export const getOrderInfo = createAsyncThunk(
  'getOrderInfo',
  async ({ requestParams, orderId, vendorId }, { rejectWithValue }) =>
    axiosGetParallelData({
      getParams: {
        urls: [
          `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/`,
          `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/filters/`,
          `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/items/`,
          `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/variants/${
            requestParams ? `?${buildQueryStringFromParams(requestParams)}` : ''
          }`,
        ],
        keys: ['orderInfo', 'orderFilters', 'orderItems', 'orderVariants'],
      },
      rejectWithValue,
    })
);

export const getOrderPaginatedDrawerItems = createAsyncThunk(
  'getOrderPaginatedDrawerItems',
  async ({ orderId, page, vendorId }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/items?page=${
        page || 1
      }`,
    })
);

export const postOrderCreate = createAsyncThunk(
  'postOrderCreate',
  async ({ vendorId, data, onSuccess }, { rejectWithValue }) =>
    axiosPostData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/`,
      data,
      onSuccess,
    })
);

export const setOrderCreateParams = createAction('setOrderCreateParams');

export const setOrderCreateProductVariantsPageParams = createAction(
  'setOrderCreateProductVariantsPageParams'
);

export const setOrderCreateQuantitiesPageParams = createAction(
  'setOrderCreateQuantitiesPageParams'
);

export const setOrderCreateSelectedBrands = createAction(
  'setOrderCreateSelectedBrands'
);

export const setOrderCreateSelectedCategories = createAction(
  'setOrderCreateSelectedCategories'
);

export const setOrderCreateSelectedCustomer = createAction(
  'setOrderCreateSelectedCustomer'
);

export const setOrderCreateSelectedLocation = createAction(
  'setOrderCreateSelectedLocation'
);

export const setOrderCreateSelectedStockLocation = createAction(
  'setOrderCreateSelectedStockLocation'
);

export const setVendorId = createAction('setVendorId');

export const toggleOrderCreateModal = createAction('toggleOrderCreateModal');

export const updateGridItemPrice = createAsyncThunk(
  'updateGridItemPrice',
  async (
    { vendorId, orderId, orderItemId, data, onError, onSuccess },
    { rejectWithValue }
  ) =>
    axiosPutData({
      data,
      onError,
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/item/${orderItemId}/update-price/`,
    })
);

export const updateGridItemQuantity = createAsyncThunk(
  'updateGridItemQuantity',
  async (
    { itemId, vendorId, orderId, data, onSuccess, onError },
    { rejectWithValue }
  ) =>
    axiosPutData({
      data,
      onError,
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/item/${itemId}/update-quantity/`,
    })
);

export const updateGridTotals = createAsyncThunk(
  'updateGridTotals',
  async (
    { vendorId, orderId, onSuccess, requestParams },
    { rejectWithValue }
  ) =>
    axiosGetData({
      onSuccess,
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/totals/${
        requestParams ? `?${buildQueryStringFromParams(requestParams)}` : ''
      }`,
    })
);

export const validateOrderItems = createAsyncThunk(
  'validateOrderItems',
  async ({ vendorId, orderId, onSuccess }, { rejectWithValue }) =>
    axiosGetData({
      rejectWithValue,
      url: `/dashboard/vendor/${vendorId}/seller-ordering/orders/${orderId}/validate-grid/`,
      onSuccess,
    })
);
