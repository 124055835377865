import {
  maxMediaQueryDesktop,
  pxToRem,
} from '@leaftrade/leaftrade-component-library';
import styled from 'styled-components';
import { LandingPageCard } from '../LandingPage.styles';

export const LandingPageFeaturedBase = styled.div`
  margin-bottom: ${pxToRem(48)};
  display: flex;
  min-height: ${pxToRem(192)};
  ${maxMediaQueryDesktop} {
    flex-direction: column;
  }
`;

export const FavoriteToggleWrapper = styled.div`
  position: absolute;
  right: ${pxToRem(4)};
  top: ${pxToRem(4)};
`;

export const LandingPageFeaturedShopAllCard = styled(LandingPageCard)`
  &:hover {
    box-shadow: unset;
    span {
      transform: matrix(-1, 0, 0, 1, 0, 0);
    }
  }
`;
