import {
  Card,
  IconArrowRight,
  Typography,
  bodySmallTypography,
  handleImgSrcError,
  maxMediaQueryDesktop,
  neutral10,
  neutral60,
  neutral90,
  pxToRem,
  spacing6,
} from '@leaftrade/leaftrade-component-library';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  featuredVendorAllCardClickEvent,
  featuredVendorCardClickEvent,
  featuredVendorCardRenderedEvent,
  featuredVendorFavoriteToggleEvent,
} from '../../../analytics/landing';
import { getLandingPageData } from '../../../../store/actions/storefrontActions';
import FavoriteToggle from '../../FavoriteToggle';
import {
  LandingPageCard,
  LandingPageCardsContainer,
  LandingPageGradientIcon,
  LandingPageGradientIconBackground,
} from '../LandingPage.styles';
import {
  FavoriteToggleWrapper,
  LandingPageFeaturedBase,
  LandingPageFeaturedShopAllCard,
} from './LandingPageFeatured.styles';

const LandingPageFeatured = ({ featuredVendors }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (featuredVendors.length > 0) {
      featuredVendors.forEach((vendor) => {
        featuredVendorCardRenderedEvent({
          vendor,
        });
      });
    }
  }, [featuredVendors]);

  return (
    <LandingPageFeaturedBase data-testid='landing-page-featured--component--div'>
      <Card
        elevation={0}
        paddingSize='large'
        sx={{
          backgroundColor: neutral10.hex,
          borderRadius: '16px',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: pxToRem(240),
          height: 'unset',
          marginRight: spacing6,
          [maxMediaQueryDesktop]: {
            marginBottom: spacing6,
            marginRight: 'unset',
            width: '100%',
          },
        }}
      >
        <Typography component='h4' sx={{ color: neutral90.hex }}>
          Featured Sellers
        </Typography>
        <Typography
          bodyStyle={bodySmallTypography}
          sx={{ color: neutral60.hex }}
        >
          Shop a curated collection of the most reputable and trusted cannabis
          sellers.
        </Typography>
      </Card>
      <LandingPageCardsContainer>
        {featuredVendors.length > 0 &&
          featuredVendors.map((featuredVendor) => (
            <LandingPageCard
              data-testid='landing-page-featured--vendor-card--div'
              key={featuredVendor.slug}
            >
              <FavoriteToggleWrapper>
                <FavoriteToggle
                  isFavorite={featuredVendor.is_favorite}
                  onSuccess={() => {
                    dispatch(getLandingPageData());
                    featuredVendorFavoriteToggleEvent({
                      toggledValue: !featuredVendor.is_favorite,
                      vendor: featuredVendor,
                    });
                  }}
                  size='small'
                  vendorId={featuredVendor.id}
                />
              </FavoriteToggleWrapper>
              <Link
                onClick={() => {
                  featuredVendorCardClickEvent({ vendor: featuredVendor });
                }}
                to={`/storefront/sellers/${featuredVendor.slug}`}
              >
                <img
                  alt={featuredVendor.name}
                  onError={handleImgSrcError}
                  src={featuredVendor.logo_url}
                />
                <span>{featuredVendor.name}</span>
              </Link>
            </LandingPageCard>
          ))}
        <LandingPageFeaturedShopAllCard data-testid='landing-page-featured--shop-all-card--div'>
          <Link
            onClick={featuredVendorAllCardClickEvent}
            to='/storefront/sellers?product_types_filters=1'
          >
            <LandingPageGradientIcon>
              <LandingPageGradientIconBackground />
              <IconArrowRight />
            </LandingPageGradientIcon>
            Explore All Sellers
          </Link>
        </LandingPageFeaturedShopAllCard>
      </LandingPageCardsContainer>
    </LandingPageFeaturedBase>
  );
};

LandingPageFeatured.propTypes = {
  featuredVendors: PropTypes.arrayOf(
    PropTypes.shape({
      coming_soon: PropTypes.bool,
      id: PropTypes.number,
      is_favorite: PropTypes.bool,
      logo_url: PropTypes.string,
      name: PropTypes.string,
      slug: PropTypes.string,
    })
  ).isRequired,
};

export default LandingPageFeatured;
