/* eslint-disable no-nested-ternary */
import { current, createSlice } from '@reduxjs/toolkit';
import { handleRejected } from '../../helpers';
import {
  addAllItemsToOrderCreate,
  addItemToOrderCreate,
  deleteAllItemsFromOrderCreate,
  deleteItemFromOrderCreate,
  getOrderCreateCustomers,
  getOrderCreateLocations,
  getOrderCreateProductVariants,
  getOrderCreateStockLocations,
  getOrderGridInfo,
  getOrderGridItems,
  getOrderInfo,
  getOrderPaginatedDrawerItems,
  postOrderCreate,
  setOrderCreateProductVariantsPageParams,
  setOrderCreateQuantitiesPageParams,
  setOrderCreateParams,
  setOrderCreateSelectedBrands,
  setOrderCreateSelectedCategories,
  setOrderCreateSelectedCustomer,
  setOrderCreateSelectedLocation,
  setOrderCreateSelectedStockLocation,
  setVendorId,
  toggleOrderCreateModal,
  updateGridItemPrice,
  updateGridItemQuantity,
  updateGridTotals,
  validateOrderItems,
} from '../actions/orderCreateActions';
import orderCreateState from '../states/orderCreateState';

export const orderCreateSlice = createSlice({
  name: 'orderCreate',
  initialState: orderCreateState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addAllItemsToOrderCreate.pending, (state) => ({
      ...state,
      order: {
        ...state.order,
        isAddingAllItems: true,
      },
    }));
    builder.addCase(addAllItemsToOrderCreate.fulfilled, (state, action) => ({
      ...state,
      productVariants: {
        ...state.productVariants,
        itemErrors: [
          ...state.productVariants.itemErrors,
          ...action.payload.map((item) => item.errors || []).flat(),
        ],
      },
      order: {
        ...state.order,
        items: [
          ...state.order.items,
          ...action.payload.map((item) => item.added_items).flat(),
        ],
        itemsBeingAdded: [],
        isAddingAllItems: false,
      },
    }));
    builder.addCase(addAllItemsToOrderCreate.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(addItemToOrderCreate.pending, (state, action) => ({
      ...state,
      order: {
        ...state.order,
        itemsBeingAdded: [
          ...state.order.itemsBeingAdded,
          ...action.meta.arg.data.variant_ids,
        ],
      },
    }));
    builder.addCase(addItemToOrderCreate.fulfilled, (state, action) => ({
      ...state,
      productVariants: {
        ...state.productVariants,
        itemErrors: [
          ...state.productVariants.itemErrors,
          ...(action.payload.errors || []),
        ],
      },
      order: {
        ...state.order,
        items: [...state.order.items, ...action.payload.added_items],
        itemsBeingAdded: [
          ...state.order.itemsBeingAdded.filter(
            (id) =>
              !action.payload.added_items
                ?.map((item) => item.variant_id)
                .includes(id) &&
              !action.payload.errors
                ?.map((error) => error.variant_id)
                .includes(id)
          ),
        ],
      },
    }));
    builder.addCase(addItemToOrderCreate.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(deleteAllItemsFromOrderCreate.pending, (state) => ({
      ...state,
      order: {
        ...state.order,
        isDeletingAllItems: true,
      },
    }));
    builder.addCase(deleteAllItemsFromOrderCreate.fulfilled, (state) => ({
      ...state,
      order: {
        ...state.order,
        productVariants: {
          ...state.productVariants,
          itemErrors: [],
        },
        items: [],
        isDeletingAllItems: false,
      },
    }));
    builder.addCase(deleteAllItemsFromOrderCreate.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(deleteItemFromOrderCreate.pending, (state, action) => ({
      ...state,
      order: {
        ...state.order,
        itemsBeingRemoved: [
          ...state.order.itemsBeingRemoved,
          action.meta.arg.orderedItemId,
        ],
      },
    }));
    builder.addCase(deleteItemFromOrderCreate.fulfilled, (state, action) => ({
      ...state,
      productVariants: {
        ...state.productVariants,
        itemErrors: [
          ...state.productVariants.itemErrors,
          ...(action.payload.errors || []),
        ],
      },
      order: {
        ...state.order,
        items: [
          ...state.order.items.filter(
            (item) => item.id !== action.meta.arg.orderedItemId
          ),
        ],
        itemsBeingRemoved: [
          ...state.order.itemsBeingRemoved.filter(
            (id) => id !== action.meta.arg.orderedItemId
          ),
        ],
      },
    }));
    builder.addCase(deleteItemFromOrderCreate.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getOrderCreateCustomers.pending, (state) => ({
      ...state,
      orderCreateModal: {
        ...state.orderCreateModal,
        isLoadingCustomers: true,
      },
    }));
    builder.addCase(getOrderCreateCustomers.fulfilled, (state, action) => ({
      ...state,
      orderCreateModal: {
        ...state.orderCreateModal,
        customers: action.payload.customers,
        isLoadingCustomers: false,
      },
    }));
    builder.addCase(getOrderCreateCustomers.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getOrderCreateLocations.pending, (state) => ({
      ...state,
      orderCreateModal: {
        ...state.orderCreateModal,
        isLoadingLocations: true,
      },
    }));
    builder.addCase(getOrderCreateLocations.fulfilled, (state, action) => {
      const locations = action.payload.customer_locations.map((location) => ({
        ...location,
        error:
          action?.payload?.errors?.find(
            (error) => error.dispensary_location_id === location.id
          ) || null,
      }));

      return {
        ...state,
        orderCreateModal: {
          ...state.orderCreateModal,
          isLoadingLocations: false,
          locations,
        },
      };
    });
    builder.addCase(getOrderCreateLocations.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getOrderCreateProductVariants.pending, (state) => ({
      ...state,
      isLoadingProductVariants: true,
    }));
    builder.addCase(
      getOrderCreateProductVariants.fulfilled,
      (state, action) => ({
        ...state,
        isLoadingProductVariants: false,
        productVariants: {
          ...state.productVariants,
          pageParams: {
            ...state.productVariants.pageParams,
            resultCount: action.payload.page_params.result_count,
          },
          variants: action.payload.variants_data,
        },
      })
    );
    builder.addCase(getOrderCreateProductVariants.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getOrderCreateStockLocations.pending, (state) => ({
      ...state,
      orderCreateModal: {
        ...state.orderCreateModal,
        isLoadingStockLocations: true,
      },
    }));
    builder.addCase(
      getOrderCreateStockLocations.fulfilled,
      (state, action) => ({
        ...state,
        orderCreateModal: {
          ...state.orderCreateModal,
          isLoadingStockLocations: false,
          stockLocations: action.payload.stock_locations,
        },
      })
    );
    builder.addCase(getOrderCreateStockLocations.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(setOrderCreateParams, (state, action) => ({
      ...state,
      productVariants: {
        ...state.productVariants,
        pageParams: {
          ...state.productVariants.pageParams,
          page: Number(action.payload.page) || 1,
          perPage: Number(action.payload['per-page']) || 25,
          sortBy: {
            ...state.productVariants.pageParams.sortBy,
            id: action.payload['order-by']?.replace('-', '') || 'name',
            desc: !!action.payload['order-by']?.startsWith('-'),
          },
        },
        searchValue: action.payload['filter-text'] || '',
        selectedBrands: action.payload['brand-filter']
          ? action.payload['brand-filter'].split(',')
          : [],
        selectedCategories: action.payload['category-filter']
          ? action.payload['category-filter'].split(',')
          : [],
        showHiddenVariants: action.payload['status-filter'] !== 'visible',
        showOutOfStock: action.payload['inventory-filter'] !== 'instock',
      },
    }));
    builder.addCase(
      setOrderCreateProductVariantsPageParams,
      (state, action) => ({
        ...state,
        productVariants: {
          ...state.productVariants,
          pageParams: action.payload,
        },
      })
    );
    builder.addCase(setOrderCreateQuantitiesPageParams, (state, action) => ({
      ...state,
      grid: {
        ...state.grid,
        pageParams: action.payload,
      },
    }));
    builder.addCase(setOrderCreateSelectedBrands, (state, action) => ({
      ...state,
      productVariants: {
        ...state.productVariants,
        selectedBrands: action.payload,
      },
    }));
    builder.addCase(setOrderCreateSelectedCategories, (state, action) => ({
      ...state,
      productVariants: {
        ...state.productVariants,
        selectedCategories: action.payload,
      },
    }));
    builder.addCase(setOrderCreateSelectedCustomer, (state, action) => ({
      ...state,
      orderCreateModal: {
        ...state.orderCreateModal,
        selectedCustomer: action.payload,
        selectedLocation: null,
      },
    }));
    builder.addCase(getOrderGridInfo.pending, (state) => ({
      ...state,
      isLoadingGrid: true,
    }));
    builder.addCase(getOrderGridInfo.fulfilled, (state, action) => ({
      ...state,
      isLoadingGrid: false,
      grid: {
        ...state.grid,
        filters: {
          brands: action.payload.brands,
          categories: action.payload.categories,
        },
      },
      order: {
        ...state.order,
        errors: action.payload.order_grid.errors,
        id: action.payload.order_grid.id,
      },
      orderCreateModal: {
        ...state.orderCreateModal,
        selectedCustomer: {
          id: action.payload.order_grid.dispensary_id,
          name: action.payload.order_grid.dispensary_name,
        },
        selectedLocation: {
          id: action.payload.order_grid.dispensary_location_id,
          name: action.payload.order_grid.dispensary_location_name,
        },
        selectedStockLocation: {
          id: action.payload.order_grid.stock_location_id,
          name: action.payload.order_grid.stock_location_name,
        },
      },
      tablePreferences: action.payload.table_preferences,
    }));
    builder.addCase(getOrderGridInfo.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getOrderGridItems.pending, (state) => ({
      ...state,
      isLoadingGridItems: true,
    }));
    builder.addCase(getOrderGridItems.fulfilled, (state, action) => ({
      ...state,
      isLoadingGridItems: false,
      grid: {
        ...state.grid,
        items: action.payload.ordered_items,
        pageParams: {
          ...state.grid.pageParams,
          page: action.payload.page_params.page,
          perPage: action.payload.page_params.per_page,
          resultCount: action.payload.page_params.result_count,
          sortByOptions: action.payload.page_params.order_by_options,
        },
        sortableColumns: action.payload.order_by_options,
      },
    }));
    builder.addCase(getOrderGridItems.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getOrderInfo.pending, (state) => ({
      ...state,
      isLoadingFilters: true,
      isLoadingOrder: true,
      isLoadingProductVariants: true,
    }));
    builder.addCase(getOrderInfo.fulfilled, (state, action) => ({
      ...state,
      isLoadingFilters: false,
      isLoadingOrder: false,
      isLoadingProductVariants: false,
      productVariants: {
        ...state.productVariants,
        filters: action.payload.orderFilters.filters,
        pageParams: {
          ...state.productVariants.pageParams,
          resultCount: action.payload.orderVariants.page_params.result_count,
        },
        variants: action.payload.orderVariants.variants_data,
      },
      order: {
        ...state.order,
        hasMoreItems: action.payload.orderItems.has_next,
        id: action.payload.orderInfo.order.id,
        items: action.payload.orderItems.ordered_items,
        itemsPageParams: {
          ...state.order.itemsPageParams,
          resultCount: action.payload.orderItems.page_params.result_count,
        },
        totals: {
          ...state.grid.totals,
          filteredQuantity:
            action.payload.orderInfo?.totals?.filtered_quantity || 0,
          filteredPrice: action.payload.orderInfo?.totals?.filtered_price || 0,
          orderMinimum: action.payload.orderInfo?.totals?.order_minimum || 0,
          totalQuantity: action.payload.orderInfo?.totals?.total_quantity || 0,
          totalPrice: action.payload.orderInfo?.totals?.total_price || 0,
          currency: action.payload.orderInfo?.totals?.currency || '',
        },
      },
      orderCreateModal: {
        ...state.orderCreateModal,
        selectedCustomer: {
          id: action.payload.orderInfo.order.dispensary_id,
          name: action.payload.orderInfo.order.dispensary_name,
        },
        selectedLocation: {
          id: action.payload.orderInfo.order.dispensary_location_id,
          name: action.payload.orderInfo.order.dispensary_location_name,
        },
        selectedStockLocation: {
          id: action.payload.orderInfo.order.stock_location_id,
          name: action.payload.orderInfo.order.stock_location_name,
        },
      },
      tablePreferences: action.payload.orderInfo.table_preferences,
    }));
    builder.addCase(getOrderInfo.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getOrderPaginatedDrawerItems.pending, (state) => ({
      ...state,
      isLoadingDrawerItems: true,
    }));
    builder.addCase(
      getOrderPaginatedDrawerItems.fulfilled,
      (state, action) => ({
        ...state,
        isLoadingDrawerItems: false,
        order: {
          ...state.order,
          hasMoreItems: action.payload.has_next,
          items: [...state.order.items, ...action.payload.ordered_items],
          itemsPageParams: {
            ...state.order.itemsPageParams,
            page: action.payload.page_params.page,
            resultCount: action.payload.page_params.result_count,
          },
        },
      })
    );
    builder.addCase(getOrderPaginatedDrawerItems.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(postOrderCreate.pending, (state) => ({
      ...state,
      isCreatingOrder: true,
    }));
    builder.addCase(postOrderCreate.fulfilled, (state, action) => ({
      ...state,

      isCreatingOrder: false,
      order: {
        ...state.order,
        id: action.payload.order_ids[0],
      },
    }));
    builder.addCase(postOrderCreate.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(setOrderCreateSelectedLocation, (state, action) => ({
      ...state,
      orderCreateModal: {
        ...state.orderCreateModal,
        selectedLocation: action.payload,
      },
    }));
    builder.addCase(setOrderCreateSelectedStockLocation, (state, action) => ({
      ...state,
      orderCreateModal: {
        ...state.orderCreateModal,
        selectedStockLocation: action.payload,
      },
    }));
    builder.addCase(setVendorId, (state, action) => ({
      ...state,
      vendorId: action.payload,
    }));
    builder.addCase(toggleOrderCreateModal, (state, action) => ({
      ...state,
      orderCreateModal: {
        ...state.orderCreateModal,
        isOpen: action.payload.isOpen,
      },
    }));
    builder.addCase(updateGridItemPrice.fulfilled, (state, action) => {
      const updatedItem = action.payload.ordered_item;
      const { items } = current(state).grid;

      const newItems = updatedItem
        ? items.map((item) =>
            item.id === updatedItem.id ? { ...item, ...updatedItem } : item
          )
        : items;

      return {
        ...state,
        grid: {
          ...state.grid,
          items: newItems,
        },
      };
    });
    builder.addCase(updateGridItemQuantity.fulfilled, (state, action) => {
      const updatedItem = action.payload.ordered_item;
      const deletedItem = action.payload.deleted_item;
      const { items } = current(state).grid;

      const newItems = deletedItem
        ? items.filter((item) => item.id !== deletedItem)
        : updatedItem
        ? items.map((item) =>
            item.id === updatedItem.id ? { ...item, ...updatedItem } : item
          )
        : items;

      return {
        ...state,
        grid: {
          ...state.grid,
          items: newItems,
        },
      };
    });
    builder.addCase(updateGridItemQuantity.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(updateGridTotals.fulfilled, (state, action) => ({
      ...state,
      order: {
        ...state.order,
        totals: {
          ...state.order.totals,
          filteredQuantity: action.payload.totals.filtered_quantity,
          filteredPrice: action.payload.totals.filtered_price,
          orderMinimum: action.payload.totals.order_minimum,
          totalQuantity: action.payload.totals.total_quantity,
          totalPrice: action.payload.totals.total_price,
          currency: action.payload.totals.currency,
        },
      },
    }));
    builder.addCase(updateGridTotals.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(validateOrderItems.fulfilled, (state, action) => ({
      ...state,
      grid: {
        ...state.grid,
        gridErrors: action.payload.errors || [],
        itemErrors: action.payload.errors_rule_items || [],
        orderErrors: action.payload.errors_rule_order || [],
      },
    }));
    builder.addCase(validateOrderItems.rejected, (state, action) =>
      handleRejected(state, action)
    );
  },
});
