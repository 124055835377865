import { createSlice, current } from '@reduxjs/toolkit';
import { handleRejected } from '../../helpers';
import { aggregateUniqueVariants } from '../../storefront/utils';
import {
  addBankAccount,
  addProductToCart,
  addProductToQuickOrder,
  addTimedBanner,
  clearCart,
  clearCartAfterCheckout,
  clearQuickOrder,
  deleteCartItem,
  deleteQuickOrderItem,
  editCartItemQuantity,
  editQuickOrderItemQuantity,
  getAllSellersData,
  getAuthUserDispensariesAndVendors,
  getCartData,
  getCartDataSummary,
  getCheckoutData,
  getLandingPageData,
  getNavData,
  getProductDetails,
  getPublicStorefrontData,
  getQuickOrderData,
  getQuickOrderNotices,
  getQuickOrderProducts,
  getSellerNavData,
  getSellerStorefrontData,
  getShopperLocations,
  getTerpeneTypes,
  getVendorPreviewCustomers,
  getVendorPreviewCustomerLocations,
  postCheckoutData,
  removeTimedBanner,
  resetProductDetails,
  setHasLimitedAccess,
  setLocationFromCart,
  setLocationFromDispensarySelection,
  setLocationFromHeader,
  setLocationFromLoadData,
  setNavigationLinks,
  setNavigationSections,
  setProductId,
  setSelectedImage,
  setSelectedQuickOrderLocations,
  setSelectedVariant,
  setSellerGridView,
  setSellersGridView,
  setShopper,
  setVendorPreviewCustomer,
  setVendorPreviewCustomerLocation,
  setVendorSlug,
  toggleAddBankAccountModal,
  toggleCartClearModal,
  toggleCartDeleteModal,
  toggleCartPreviewModal,
  toggleConfirmAgeModal,
  toggleFavoriteVendor,
  togglePrivacyPolicyModal,
  toggleQuickOrderAddModal,
  toggleQuickOrderClearModal,
  toggleQuickOrderDeleteModal,
  toggleTermsOfServiceModal,
} from '../actions/storefrontActions';
import storefrontState from '../states/storefrontState';

const buildProductVariants = ({
  products,
  storefrontAggregateVariantsEnabled,
}) => {
  let builtProduct;

  if (products?.length) {
    builtProduct = products.map((product) => {
      let uniqueVariants;
      let variantsByKey;
      let productVariants = product.variants;

      if (storefrontAggregateVariantsEnabled) {
        const aggregatedVariants = aggregateUniqueVariants(product.variants);
        uniqueVariants = aggregatedVariants.uniqueVariants;
        variantsByKey = aggregatedVariants.variantsByKey;
        productVariants = uniqueVariants;
      }

      return {
        ...product,
        uniqueVariants,
        variants: productVariants,
        variantsByKey,
      };
    });
  }

  return builtProduct;
};

export const storefrontSlice = createSlice({
  name: 'storefront',
  initialState: storefrontState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addBankAccount.pending, (state) => ({
      ...state,
      checkout: {
        ...state.checkout,
        addBankAccountModal: {
          ...state.checkout.addBankAccountModal,
          isAddingBankAccount: true,
        },
      },
    }));
    builder.addCase(addBankAccount.fulfilled, (state, action) => {
      if (action.payload.errors) {
        return {
          ...state,
          checkout: {
            ...state.checkout,
            addBankAccountModal: {
              ...state.checkout.addBankAccountModal,
              isAddingBankAccount: false,
              errors: action.payload.errors,
            },
          },
        };
      }
      return {
        ...state,
        checkout: {
          ...state.checkout,
          addBankAccountModal: {
            ...state.checkout.addBankAccountModal,
            isAddingBankAccount: false,
          },
          leafPayData: {
            ...state.checkout.leafPayData,
            bankAccounts: [
              ...state.checkout.leafPayData.bankAccounts,
              {
                id: action.payload.id,
                displayName: action.payload.display_name,
              },
            ],
          },
        },
      };
    });
    builder.addCase(addBankAccount.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(addProductToCart.pending, (state) => ({
      ...state,
      cart: {
        ...state.cart,
        isLoadingCartData: true,
      },
    }));
    builder.addCase(addProductToCart.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(addProductToQuickOrder, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        items: [...state.quickOrderData.items, action.payload.item],
      },
    }));
    builder.addCase(addTimedBanner, (state, action) => ({
      ...state,
      timedBanners: [
        ...state.timedBanners,
        {
          id: action.payload.id,
          message: action.payload.message,
          preventAutoClose: action.payload.preventAutoClose,
          severity: action.payload.severity,
          timeout: action.payload.timeout,
          title: action.payload.title,
        },
      ],
    }));
    builder.addCase(clearCart.pending, (state) => ({
      ...state,
      cart: {
        ...state.cart,
        clearCartModal: {
          ...state.cart.clearCartModal,
          isClearingCart: true,
        },
      },
    }));
    builder.addCase(clearCart.fulfilled, (state, action) => {
      const { payload } = action;

      const newCart = payload?.carts[0] || {};

      return {
        ...state,
        cart: {
          ...state.cart,
          cartAlerts: newCart.cart_notices,
          cartItemAlerts: newCart.cart_item_notices,
          clearCartModal: {
            isOpen: false,
            isClearingCart: false,
          },
          selectedCart: newCart,
        },
      };
    });
    builder.addCase(clearCart.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(clearCartAfterCheckout, (state) => ({
      ...state,
      cart: {
        ...state.cart,
        cartAlerts: [],
        cartItemAlerts: [],
        selectedCart: {},
      },
    }));
    builder.addCase(clearQuickOrder.pending, (state) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        clearQuickOrderModal: {
          ...state.quickOrderData.clearQuickOrderModal,
          isClearingQuickOrder: true,
        },
      },
    }));
    builder.addCase(clearQuickOrder.fulfilled, (state, action) => ({
      ...state,
      cart: {
        ...state.cart,
        multiCartSummary: undefined,
        selectedCart: {
          token: undefined,
        },
      },
      quickOrderData: {
        ...state.quickOrderData,
        clearQuickOrderModal: {
          isOpen: false,
          isClearingQuickOrder: false,
        },
        items: action.payload.items,
        quickOrderAlerts: action.payload.cart_notices,
        quickOrderItemAlerts: action.payload.cart_item_notices,
        totalItems: action.payload.totalItems,
        totalPrice: action.payload.totalPrice,
      },
    }));
    builder.addCase(deleteCartItem.pending, (state) => ({
      ...state,
      cart: {
        ...state.cart,
        deleteItemModal: {
          ...state.cart.deleteItemModal,
          isRemovingItem: true,
        },
      },
    }));
    builder.addCase(deleteCartItem.fulfilled, (state, action) => {
      const { payload } = action;

      const currentlySelectedCart = current(state).cart.selectedCart;

      const updatedCartFromPayload =
        payload?.carts?.find(
          (cart) => cart.token === currentlySelectedCart.token
        ) || {};

      return {
        ...state,
        cart: {
          cartAlerts: payload.cart_notices,
          cartItemAlerts: payload.cart_item_notices,
          ...state.cart,
          deleteItemModal: {
            ...state.cart.deleteItemModal,
            isOpen: false,
            item: undefined,
            isRemovingItem: false,
          },
          selectedCart: updatedCartFromPayload,
        },
      };
    });
    builder.addCase(deleteCartItem.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(deleteQuickOrderItem.pending, (state) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        deleteItemModal: {
          ...state.quickOrderData.deleteItemModal,
          isRemovingItem: true,
        },
      },
    }));
    builder.addCase(deleteQuickOrderItem.fulfilled, (state, action) => {
      const stateItemsWithDeletedItemRemoved =
        state.quickOrderData.items.filter(
          (item) =>
            item.cartItemId !==
            state.quickOrderData.deleteItemModal.item.cartItemId
        );
      const reducedItems = [
        ...action.payload.items,
        ...stateItemsWithDeletedItemRemoved,
      ].reduce((acc, current) => {
        const x = acc.find((item) => item.cartItemId === current.cartItemId);
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);

      return {
        ...state,
        quickOrderData: {
          ...state.quickOrderData,
          deleteItemModal: {
            ...state.quickOrderData.deleteItemModal,
            isOpen: false,
            item: undefined,
            isRemovingItem: false,
          },
          items: reducedItems,
          quickOrderAlerts: action.payload.cart_notices,
          quickOrderItemAlerts: action.payload.cart_item_notices,
          totalItems: action.payload.totalItems,
          totalPrice: action.payload.totalPrice,
        },
      };
    });
    builder.addCase(deleteQuickOrderItem.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(editCartItemQuantity.fulfilled, (state, action) => {
      const { payload } = action;

      const currentlySelectedCart = current(state).cart.selectedCart;

      const updatedCartFromPayload = payload?.carts?.find(
        (cart) => cart.token === currentlySelectedCart.token
      );

      return {
        ...state,
        cart: {
          ...state.cart,
          cartAlerts: payload.cart_notices || [],
          cartItemAlerts: payload.cart_item_notices || [],
          selectedCart: updatedCartFromPayload || {},
        },
      };
    });
    builder.addCase(editCartItemQuantity.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(editQuickOrderItemQuantity.pending, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        editingAllItemQuantity: {
          isEditing: true,
          item: action.meta.arg.item,
        },
        numberOfPendingEdits: state.quickOrderData.numberOfPendingEdits + 1,
      },
    }));
    builder.addCase(editQuickOrderItemQuantity.fulfilled, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        editingAllItemQuantity: {
          isEditing: false,
          item: undefined,
        },
        items: current(state).quickOrderData.items.map((item) => {
          const itemFromPayload = action.payload.items.find(
            (payloadItem) => payloadItem.cartItemId === item.cartItemId
          );

          if (itemFromPayload) {
            return itemFromPayload;
          }

          return item;
        }),
        numberOfPendingEdits: state.quickOrderData.numberOfPendingEdits - 1,
        quickOrderAlerts: action.payload.cart_notices,
        quickOrderItemAlerts: action.payload.cart_item_notices,
        totalItems: action.payload.totalItems,
        totalPrice: action.payload.totalPrice,
      },
    }));
    builder.addCase(editQuickOrderItemQuantity.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getAllSellersData.pending, (state, action) => {
      const { isLoadingMore, isRefreshingFavorites } = action.meta.arg;

      const hideLoadingSpinner = isLoadingMore || isRefreshingFavorites;

      return {
        ...state,
        sellersPageData: {
          ...state.sellersPageData,
          isLoadingAllSellersData: !hideLoadingSpinner,
        },
      };
    });
    builder.addCase(getAllSellersData.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const { isLoadingMore, isRefreshingFavorites } = meta.arg;

      if (isRefreshingFavorites) {
        return {
          ...state,
          sellersPageData: {
            ...state.sellersPageData,
            favoriteVendors: payload.favorite_vendors,
          },
        };
      }

      if (isLoadingMore) {
        return {
          ...state,
          sellersPageData: {
            ...state.sellersPageData,
            hasAdditionalSellers: payload.has_additional_vendor,
            vendors: [...state.sellersPageData.vendors, ...payload.vendors],
          },
        };
      }

      return {
        ...state,
        sellersPageData: {
          ...state.sellersPageData,
          brands: payload.brands,
          categories: payload.categories,
          favoriteVendors: payload.favorite_vendors,
          hasAdditionalSellers: payload.has_additional_vendor,
          isLoadingAllSellersData: false,
          productTypes: [
            {
              id: 1,
              key: 'cannabis',
              name: 'Cannabis',
            },
            {
              id: 2,
              key: 'non-cannabis',
              name: 'Non-Cannabis',
            },
          ],
          terpeneTypes: payload.terpene_types,
          vendors: payload.vendors,
        },
      };
    });
    builder.addCase(getAllSellersData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getCartData.pending, (state, action) => ({
      ...state,
      cart: {
        ...state.cart,
        isLoadingCartData: action.meta.arg.setIsLoading,
      },
    }));
    builder.addCase(getCartData.fulfilled, (state, action) => {
      const { cartData, multiCartSummary } = action.payload;
      let selectedCart = {};

      if ('carts' in cartData) {
        if (cartData.carts.length === 1) {
          [selectedCart] = cartData.carts;
        } else {
          selectedCart =
            cartData.carts.find(
              (cart) =>
                cart.locationId === current(state).shopper.currentLocation.id
            ) || {};
        }
      } else {
        selectedCart = cartData.cart;
      }

      return {
        ...state,
        cart: {
          ...state.cart,
          cartAlerts: action.payload?.cartNotices?.cart_notices || [],
          cartItemAlerts: action.payload?.cartNotices?.cart_item_notices || [],
          dataFetched: true,
          isLoadingCartData: false,
          isLoadingCartDataFromCart: false,
          multiCartSummary,
          selectedCart,
        },
        shopper: {
          ...state.shopper,
          locations: state.shopper.locations.map((loc) => {
            if (loc.id.toString() === selectedCart?.locationId?.toString()) {
              return {
                id: loc.id.toString(),
                name: loc.name,
                token: selectedCart.token,
              };
            }

            return loc;
          }),
        },
        vendor: {
          ...state.vendor,
          name: cartData.vendorName,
          id: cartData.vendorId,
        },
      };
    });
    builder.addCase(getCartData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getCartDataSummary.fulfilled, (state, action) => ({
      ...state,
      cart: {
        ...state.cart,
        multiCartSummary: action.payload,
      },
    }));
    builder.addCase(getCartDataSummary.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getCheckoutData.pending, (state) => ({
      ...state,
      checkout: {
        ...state.checkout,
        isLoadingCheckoutData: true,
      },
    }));
    builder.addCase(getCheckoutData.fulfilled, (state, action) => ({
      ...state,
      checkout: {
        ...state.checkout,
        canPlaceOrder: action.payload.can_place_order,
        checkoutAlerts: action.payload.checkout_notices,
        checkoutData: {
          ...state.checkout.checkoutData,
          discount: action.payload.discount,
          estimatedTax: action.payload.estimated_tax,
          items: action.payload.items,
          shippingMethods: action.payload.shipping_methods,
          subtotal: action.payload.subtotal,
          surcharge: action.payload.surcharge,
          total: action.payload.total,
          totalShipping: action.payload.total_shipping,
        },
        isAdmin: action.payload.is_admin,
        isLoadingCheckoutData: false,
        leafPayData: {
          isEnabled: action.payload.leaf_pay_enabled,
          bankAccounts: action.payload.bank_accounts,
        },
      },
    }));
    builder.addCase(getCheckoutData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(
      getAuthUserDispensariesAndVendors.fulfilled,
      (state, action) => {
        const { dispensaries, vendors } = action.payload;

        return {
          ...state,
          authUser: {
            ...state.authUser,
            dispensaries,
            vendors,
          },
        };
      }
    );
    builder.addCase(
      getAuthUserDispensariesAndVendors.rejected,
      (state, action) => handleRejected(state, action)
    );
    builder.addCase(getShopperLocations.fulfilled, (state, action) => {
      const { locations } = action.payload;

      const currentLocation = locations?.find(
        (loc) =>
          loc.id.toString() ===
          current(state).shopper?.currentLocation?.id.toString()
      );

      const modifiedLocations = locations?.map((loc) => ({
        id: loc.id.toString(),
        name: loc.name,
        token: loc.cart_id,
      }));

      return {
        ...state,
        cart: {
          ...state.cart,
          isLoadingCartData: !!currentLocation?.cart_id,
          selectedCart: {
            ...state.cart.selectedCart,
            token: currentLocation?.cart_id,
          },
        },
        quickOrderData: {
          ...state.quickOrderData,
          selectedLocations: modifiedLocations || [],
        },
        shopper: {
          ...state.shopper,
          locations: modifiedLocations || [],
        },
      };
    });
    builder.addCase(getShopperLocations.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(postCheckoutData.pending, (state) => ({
      ...state,
      checkout: {
        ...state.checkout,
        isPostingCheckoutData: true,
      },
    }));
    builder.addCase(postCheckoutData.fulfilled, (state) => ({
      ...state,
      checkout: {
        ...state.checkout,
        isPostingCheckoutData: false,
      },
    }));
    builder.addCase(postCheckoutData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getLandingPageData.fulfilled, (state, action) => {
      const { payload } = action;

      return {
        ...state,
        landingPageData: {
          ...state.landingPageData,
          carouselSponsors: payload.carousel_advertisements,
          categories: payload.categories,
          favoriteVendors: payload.favorite_vendors,
          featuredVendors: payload.featured_vendors,
          isLoadingLandingData: false,
          sponsors: payload.advertisements,
        },
      };
    });
    builder.addCase(getLandingPageData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getNavData.fulfilled, (state, action) => ({
      ...state,
      navigation: {
        ...state.navigation,
        brands: action.payload.brands,
        vendors: action.payload.vendors,
      },
    }));
    builder.addCase(getNavData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getProductDetails.pending, (state) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        isLoadingProductDetails: true,
      },
    }));
    builder.addCase(getProductDetails.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const {
        images,
        product,
        variants,
        vendor_settings: vendorSettings,
      } = payload;
      const { batchQueuesEnabled, defaultVariantId } = meta.arg;

      const storefrontAggregateVariantsEnabled =
        vendorSettings.storefront_aggregate_variants;

      let errorsAndWarnings;
      let selectedImage;
      let selectedVariantId;
      let uniqueVariants;
      let variantsByKey;

      if (storefrontAggregateVariantsEnabled) {
        const aggregatedVariants = aggregateUniqueVariants(variants);
        uniqueVariants = aggregatedVariants.uniqueVariants;
        variantsByKey = aggregatedVariants.variantsByKey;
        if (
          defaultVariantId &&
          !uniqueVariants.find(
            (variant) => variant.id.toString() === defaultVariantId
          )
        ) {
          selectedVariantId = uniqueVariants[0].id;
          errorsAndWarnings = undefined;
        }
      }
      if (images?.length) {
        selectedImage = images.find((image) => image.position === 0).url;
      }

      if (!defaultVariantId) {
        if (batchQueuesEnabled) {
          selectedVariantId = product?.package_sizes[0]?.id;
        } else {
          selectedVariantId = variants[0]?.id;
        }
      } else {
        selectedVariantId = defaultVariantId;
      }

      const selectedVariant = batchQueuesEnabled
        ? product?.package_sizes?.find(
            (packageSize) => packageSize.id.toString() === selectedVariantId
          )
        : variants?.find(
            (variant) => variant.id.toString() === selectedVariantId
          );

      const {
        brands,
        description,
        id,
        is_saleable: isSaleable,
        name,
        package_sizes: packageSizes,
        phenotypes,
      } = batchQueuesEnabled ? product : payload;

      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          availableVariants: payload.available_variants,
          brands,
          cart: payload.cart,
          description,
          derivedPhenotype: payload.derived_phenotype,
          errorsAndWarnings,
          id,
          images: payload.images,
          isSaleable,
          isLoadingProductDetails: false,
          name,
          packageSizes,
          phenotypes,
          marketingMaterials: payload.marketing_materials,
          instructions: payload.instructions,
          strains: payload.strains,
          selectedImage,
          selectedVariant,
          selectedVariantId,
          uniqueVariants,
          variants: batchQueuesEnabled
            ? product.package_sizes
            : payload.variants,
          variantsByKey,
          vendorId: payload.vendor_id,
        },
        vendor: {
          ...state.vendor,
          settings: {
            ...state.vendor.settings,
            settingsDeprecated: payload.vendor_settings,
          },
        },
      };
    });
    builder.addCase(getProductDetails.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getPublicStorefrontData.pending, (state, action) => ({
      ...state,
      publicMenu: {
        ...state.publicMenu,
        isLoadingPublicStorefrontData: !action.meta.arg.isLoadingMore,
      },
    }));
    builder.addCase(getPublicStorefrontData.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const { vendor } = payload;

      return {
        ...state,
        publicMenu: {
          ...state.publicMenu,
          bannerText: vendor.storefront_banner_text,
          bannerTitle: vendor.storefront_banner_title,
          billingStatus: vendor.billing_status,
          brands: vendor.brands,
          categories: vendor.categories,
          description: vendor.storefront_vendor_description,
          filteredProducts: payload.filtered_products,
          hasAdditionalProduct: payload.has_additional_product,
          heroImage: vendor.storefront_hero_image,
          heroImageLinkTarget: vendor.storefront_hero_image_link_target,
          id: vendor.id,
          isActive: vendor.is_active,
          isFavorite: vendor.is_favorite,
          isLoadingPublicStorefrontData: false,
          logo: vendor.logo,
          maxPrice: payload.max_price,
          minimumOrder: vendor.minimum_order,
          name: vendor.name,
          phenotypes: vendor.phenotypes,
          products: meta.arg.isLoadingMore
            ? [...state.publicMenu.products, ...payload.products]
            : payload.products,
          productTypes: vendor.product_types,
          regions: vendor.regions,
          slug: vendor.slug,
          strains: vendor.strains,
          subcategories: vendor.subcategories,
          url: vendor.url,
          website: vendor.website,
        },
        vendor: {
          ...state.vendor,
          name: vendor.name,
          settings: {
            ...state.vendor.settings,
            allowOverOrdering: vendor.allow_overordering,
            batchQueuesEnabled: vendor.batch_queues_storefront,
            defaultSort: vendor.default_storefront_sort,
            isGridView: !vendor.default_list_view_storefront,
            displayLiveInventoryCount: vendor.display_live_inventory_count,
            displayPricePerEach: vendor.display_price_per_each,
            settingsDeprecated: vendor.settings_deprecated,
          },
        },
      };
    });
    builder.addCase(getPublicStorefrontData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getQuickOrderData.pending, (state) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        isLoadingQuickOrderData: true,
      },
    }));
    builder.addCase(getQuickOrderData.fulfilled, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        isLoadingQuickOrderData: false,
        items: action.payload.quickOrderData.items,
        quickOrderAlerts: action.payload.quickOrderNotices.cart_notices,
        quickOrderItemAlerts:
          action.payload.quickOrderNotices.cart_item_notices,
        totalItems: action.payload.quickOrderData.totalItems,
        totalPrice: action.payload.quickOrderData.totalPrice,
      },
    }));
    builder.addCase(getQuickOrderData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getQuickOrderNotices.fulfilled, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        quickOrderAlerts: action.payload.cart_notices,
        quickOrderItemAlerts: action.payload.cart_item_notices,
      },
    }));
    builder.addCase(getQuickOrderNotices.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getQuickOrderProducts.pending, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        addToQuickOrderModal: {
          ...state.quickOrderData.addToQuickOrderModal,
          hasAdditionalProduct: false,
          isLoadingQuickOrderProducts: !action.meta.arg.isLoadingMore,
        },
      },
    }));
    builder.addCase(getQuickOrderProducts.fulfilled, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        addToQuickOrderModal: {
          ...state.quickOrderData.addToQuickOrderModal,
          hasAdditionalProduct: action.payload.has_additional_product,
          isLoadingQuickOrderProducts: false,
          products: action.meta.arg.isLoadingMore
            ? [
                ...state.quickOrderData.addToQuickOrderModal.products,
                ...action.payload.products,
              ]
            : action.payload.products,
        },
      },
      vendor: {
        ...state.vendor,
        name: action.payload.vendor.name,
        settings: {
          ...state.vendor.settings,
          allowOverOrdering: action.payload.vendor.allow_overordering,
          batchQueuesEnabled: action.payload.vendor.batch_queues_storefront,
          defaultSort: action.payload.vendor.default_storefront_sort,
          isGridView: action.meta.arg.isLoadingMore
            ? state.vendor.settings.isGridView
            : false,
          displayLiveInventoryCount:
            action.payload.vendor.display_live_inventory_count,
          displayPricePerEach: action.payload.vendor.display_price_per_each,
          settingsDeprecated: action.payload.vendor.settings_deprecated,
        },
      },
    }));
    builder.addCase(getSellerNavData.fulfilled, (state, action) => {
      const { payload } = action;

      return {
        ...state,
        sellerPageData: {
          ...state.sellerPageData,
          bannerText: payload.storefront_banner_text,
          bannerTitle: payload.storefront_banner_title,
          billingStatus: payload.billing_status,
          brands: payload.brands,
          cart: payload.cart,
          categories: payload.categories,
          description: payload.storefront_vendor_description,
          heroImage: payload.storefront_hero_image,
          heroImageLinkTarget: payload.storefront_hero_image_link_target,
          id: payload.id,
          isActive: payload.is_active,
          isFavorite: payload.is_favorite,
          isLoadingSellerNavData: false,
          logo: payload.logo,
          minimumOrder: payload.minimum_order,
          name: payload.name,
          phenotypes: payload.phenotypes,
          productTypes: payload.product_types,
          regions: payload.regions,
          slug: payload.slug,
          strains: payload.strains,
          subcategories: payload.subcategories,
          tags: payload.tags,
          terpeneTypes: payload.terpene_types,
          url: payload.url,
          website: payload.website,
        },
        vendor: {
          ...state.vendor,
          logo: payload.logo,
          name: payload.name,
          settings: {
            ...state.vendor.settings,
            allowOverOrdering: payload.allow_overordering,
            batchQueuesEnabled: payload.batch_queues_storefront,
            defaultSort: payload.default_storefront_sort,
            displayLiveInventoryCount: payload.display_live_inventory_count,
            displayPricePerEach: payload.display_price_per_each,
            isGridView: !payload.default_list_view_storefront,
            settingsDeprecated: payload.settings_deprecated,
          },
        },
      };
    });
    builder.addCase(getSellerNavData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getSellerStorefrontData.pending, (state, action) => {
      const { isLoadingMore } = action.meta.arg;
      return {
        ...state,
        sellerPageData: {
          ...state.sellerPageData,
          isLoadingSellerStorefrontData: !isLoadingMore,
        },
      };
    });
    builder.addCase(getSellerStorefrontData.fulfilled, (state, action) => {
      const { meta, payload } = action;
      const { isLoadingMore } = meta.arg;

      const storefrontAggregateVariantsEnabled =
        payload.vendor.settings_deprecated.storefront_aggregate_variants;

      const batchQueuesEnabled = payload.vendor.batch_queues_storefront;

      let checkedProducts;

      if (payload.products?.length) {
        checkedProducts = buildProductVariants({
          products: payload.products,
          storefrontAggregateVariantsEnabled,
        });
      }

      if (isLoadingMore) {
        return {
          ...state,
          sellerPageData: {
            ...state.sellerPageData,
            hasAdditionalProduct: payload.has_additional_product,
            products: [...state.sellerPageData.products, ...checkedProducts],
          },
        };
      }

      return {
        ...state,
        sellerPageData: {
          ...state.sellerPageData,
          batchQueuesEnabled,
          filteredProducts: payload.filtered_products,
          hasAdditionalProduct: payload.has_additional_product,
          isLoadingSellerStorefrontData: false,
          maxPrice: payload.max_price,
          products: checkedProducts,
          sortBy: payload.sort_args,
        },
      };
    });
    builder.addCase(getSellerStorefrontData.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getTerpeneTypes.pending, (state) => state);
    builder.addCase(getTerpeneTypes.fulfilled, (state, action) => ({
      ...state,
      terpeneTypes: {
        ...(state.terpeneTypes || {}),
        ...action.payload.terpene_types.reduce(
          (bySlugs, terpeneType) => ({
            ...bySlugs,
            [terpeneType.slug]: terpeneType,
          }),
          {}
        ),
      },
    }));
    builder.addCase(getTerpeneTypes.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(getVendorPreviewCustomers.fulfilled, (state, action) => ({
      ...state,
      vendorPreview: {
        ...state.vendorPreview,
        customers: action.payload.customers,
      },
    }));
    builder.addCase(getVendorPreviewCustomers.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(
      getVendorPreviewCustomerLocations.fulfilled,
      (state, action) => ({
        ...state,
        vendorPreview: {
          ...state.vendorPreview,
          locations: action.payload.customer_locations,
        },
      })
    );
    builder.addCase(
      getVendorPreviewCustomerLocations.rejected,
      (state, action) => handleRejected(state, action)
    );
    builder.addCase(removeTimedBanner, (state, action) => ({
      ...state,
      timedBanners: state.timedBanners.filter(
        (banner) => banner.id !== action.payload
      ),
    }));
    builder.addCase(resetProductDetails, (state) => ({
      ...state,
      productDetails: {
        ...storefrontState.productDetails,
      },
    }));
    builder.addCase(setHasLimitedAccess, (state, action) => ({
      ...state,
      hasLimitedAccess: action.payload,
    }));
    builder.addCase(setLocationFromCart.pending, (state) => ({
      ...state,
      cart: {
        ...state.cart,
        isLoadingCartDataFromCart: true,
      },
    }));
    builder.addCase(setLocationFromCart.fulfilled, (state, action) => ({
      ...state,
      cart: {
        ...state.cart,
        cartAlerts: action.payload.messages,
        selectedCart: {
          token:
            current(state).shopper.locations.find(
              (loc) =>
                loc.id.toString() ===
                action.payload.current_location.id.toString()
            )?.token || null,
        },
      },
      shopper: {
        ...state.shopper,
        dispensary: {
          ...state.shopper.dispensary,
          id: action.payload.current_location.dispensary.id,
          name: action.payload.current_location.dispensary.name,
        },
        currentLocation: {
          ...state.shopper.currentLocation,
          id: action.payload.current_location.id,
          name: action.payload.current_location.name,
          token:
            current(state).shopper.locations.find(
              (loc) =>
                loc.id.toString() ===
                action.payload.current_location.id.toString()
            )?.token || null,
        },
      },
    }));
    builder.addCase(setLocationFromCart.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(
      setLocationFromDispensarySelection.fulfilled,
      (state, action) => ({
        ...state,
        shopper: {
          ...state.shopper,
          dispensary: {
            ...state.shopper.dispensary,
            id: action.payload.current_location.dispensary.id,
            name: action.payload.current_location.dispensary.name,
          },
          currentLocation: {
            ...state.shopper.currentLocation,
            id: action.payload.current_location.id,
            name: action.payload.current_location.name,
          },
        },
      })
    );
    builder.addCase(
      setLocationFromDispensarySelection.rejected,
      (state, action) => handleRejected(state, action)
    );
    builder.addCase(setLocationFromHeader.pending, (state) => ({
      ...state,
      cart: {
        ...state.cart,
        isLoadingCartData: true,
      },
    }));
    builder.addCase(setLocationFromHeader.fulfilled, (state, action) => {
      const currentLocationFromLocationsState = current(
        state
      ).shopper.locations.find(
        (loc) =>
          loc.id.toString() === action.payload.current_location.id.toString()
      );
      const cartToken =
        currentLocationFromLocationsState?.cart_id ||
        currentLocationFromLocationsState?.token ||
        null;

      return {
        ...state,
        cart: {
          ...state.cart,
          cartAlerts: action.payload.messages,
          isLoadingCartData: !!cartToken,
          selectedCart: {
            token: cartToken,
          },
        },
        shopper: {
          ...state.shopper,
          dispensary: {
            ...state.shopper.dispensary,
            id: action.payload.current_location.dispensary.id,
            name: action.payload.current_location.dispensary.name,
          },
          currentLocation: {
            ...state.shopper.currentLocation,
            id: action.payload.current_location.id,
            name: action.payload.current_location.name,
            token: cartToken || null,
          },
        },
      };
    });
    builder.addCase(setLocationFromHeader.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(setLocationFromLoadData, (state, action) => ({
      ...state,
      shopper: {
        ...state.shopper,
        dispensary: {
          ...state.shopper.dispensary,
          id: action.payload.dispensary.id,
          name: action.payload.dispensary.name,
        },
        currentLocation: {
          ...state.shopper.currentLocation,
          id: action.payload.id,
          name: action.payload.name,
        },
      },
    }));
    builder.addCase(setNavigationLinks, (state, action) => ({
      ...state,
      navigation: {
        ...state.navigation,
        links: action.payload,
      },
    }));
    builder.addCase(setNavigationSections, (state, action) => ({
      ...state,
      navigation: {
        ...state.navigation,
        sections: action.payload,
      },
    }));
    builder.addCase(setProductId, (state, action) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        productId: action.payload,
      },
    }));
    builder.addCase(setSelectedImage, (state, action) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        selectedImage: action.payload,
      },
    }));
    builder.addCase(setSelectedQuickOrderLocations, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        selectedLocations: action.payload,
      },
    }));
    builder.addCase(setSelectedVariant, (state, action) => ({
      ...state,
      productDetails: {
        ...state.productDetails,
        selectedVariant: action.payload,
        selectedVariantId: action.payload.id.toString(),
      },
    }));
    builder.addCase(setSellerGridView, (state, action) => ({
      ...state,
      vendor: {
        ...state.vendor,
        settings: {
          ...state.vendor.settings,
          isGridView: action.payload,
        },
      },
    }));
    builder.addCase(setSellersGridView, (state, action) => ({
      ...state,
      sellersPageData: {
        ...state.sellersPageData,
        isGridView: action.payload,
      },
    }));
    builder.addCase(setShopper, (state, action) => ({
      ...state,
      shopper: {
        ...state.shopper,
        dispensary: {
          ...state.shopper.dispensary,
          ...action.payload.dispensary,
        },
        isCustomer: action.payload.isCustomer,
        isStaff: action.payload.isStaff,
        isSuperuser: action.payload.isSuperuser,
        isVendor: action.payload.isVendor,
      },
    }));
    builder.addCase(setVendorSlug, (state, action) => ({
      ...state,
      cart: {
        ...storefrontState.cart,
        selectedCart: {
          ...storefrontState.cart.selectedCart,
          token: undefined,
        },
      },
      quickOrderData: {
        ...storefrontState.quickOrderData,
      },
      vendor: {
        ...state.vendor,
        slug: action.payload,
      },
    }));
    builder.addCase(setVendorPreviewCustomer, (state, action) => ({
      ...state,
      vendorPreview: {
        ...state.vendorPreview,
        customer: action.payload,
      },
    }));
    builder.addCase(setVendorPreviewCustomerLocation, (state, action) => ({
      ...state,
      vendorPreview: {
        ...state.vendorPreview,
        location: action.payload,
      },
    }));
    builder.addCase(toggleAddBankAccountModal, (state, action) => ({
      ...state,
      checkout: {
        ...state.checkout,
        addBankAccountModal: {
          isOpen: action.payload.isOpen,
        },
      },
    }));
    builder.addCase(toggleCartClearModal, (state, action) => ({
      ...state,
      cart: {
        ...state.cart,
        clearCartModal: {
          isOpen: action.payload.isOpen,
        },
      },
    }));
    builder.addCase(toggleCartDeleteModal, (state, action) => ({
      ...state,
      cart: {
        ...state.cart,
        deleteItemModal: {
          isOpen: action.payload.isOpen,
          item: action.payload.item,
        },
      },
    }));
    builder.addCase(toggleCartPreviewModal, (state, action) => ({
      ...state,
      cart: {
        ...state.cart,
        cartPreviewModal: {
          isOpen: action.payload.isOpen,
        },
      },
    }));
    builder.addCase(toggleConfirmAgeModal, (state, action) => ({
      ...state,
      publicMenu: {
        ...state.publicMenu,
        confirmAgeModal: {
          isOpen: action.payload.isOpen,
        },
      },
    }));
    builder.addCase(toggleFavoriteVendor.pending, (state, action) => ({
      ...state,
      favoriteVendorToggleData: {
        isTogglingFavorite: true,
        favoriteVendorId: action.meta.arg.vendorId,
      },
    }));
    builder.addCase(toggleFavoriteVendor.fulfilled, (state) => ({
      ...state,
      favoriteVendorToggleData: {
        isTogglingFavorite: false,
        favoriteVendorId: undefined,
      },
    }));
    builder.addCase(toggleFavoriteVendor.rejected, (state, action) =>
      handleRejected(state, action)
    );
    builder.addCase(togglePrivacyPolicyModal, (state, action) => ({
      ...state,
      checkout: {
        ...state.checkout,
        privacyPolicyModal: {
          isOpen: action.payload.isOpen,
        },
      },
    }));
    builder.addCase(toggleQuickOrderAddModal, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        addToQuickOrderModal: {
          ...state.quickOrderData.addToQuickOrderModal,
          isOpen: action.payload.isOpen,
        },
      },
    }));
    builder.addCase(toggleQuickOrderClearModal, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        clearQuickOrderModal: {
          ...state.quickOrderData.clearQuickOrderModal,
          isOpen: action.payload.isOpen,
        },
      },
    }));
    builder.addCase(toggleQuickOrderDeleteModal, (state, action) => ({
      ...state,
      quickOrderData: {
        ...state.quickOrderData,
        deleteItemModal: {
          ...state.quickOrderData.deleteItemModal,
          isOpen: action.payload.isOpen,
          item: action.payload.item,
        },
      },
    }));
    builder.addCase(toggleTermsOfServiceModal, (state, action) => ({
      ...state,
      checkout: {
        ...state.checkout,
        termsOfServiceModal: {
          isOpen: action.payload.isOpen,
        },
      },
    }));
  },
});
